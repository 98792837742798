<template>
	<div>
		<div class="content">
			<!-- 补单 -->
			<div class="mb20 pageTitle"><b>平台利润划转到商户</b></div>
			<div>
				<el-form class="form" ref="form" :model="form" label-width="120px" v-loading='loading'>
					<el-form-item label="可用余额:">
						<span>{{userInfo.money | tofixed}}</span>
					</el-form-item>
					<el-form-item :label="$t('main.orderMenu.merchantNumber')">
						<el-input v-model="business_no" :placeholder="$t('main.orderMenu.pleaseEnterTheMerchantNumber')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('main.orderMenu.merchantName')">
						<el-select v-model="form.business_no" :placeholder="$t('main.orderMenu.pleaseSelect')" style="display: block;" value-ley="id" @change="chooseTra">
							<el-option v-for="item in traList" :key="item.business_no" :label="item.business_name" :value="item.business_no"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item :label="$t('main.orderMenu.passageway')">
						<el-table border :data="paywayTypeList" size="mini">
							<el-table-column :label="$t('main.orderMenu.passageway')" align="center">
								<template slot-scope="scope">
									<div>
									<span>{{scope.row.payway_type_name}}</span>
									<!-- <el-checkbox @change="addChange(scope.row)" v-model="scope.row.selected" :disabled="manualloading"></el-checkbox> -->
									</div>
									
								</template>
							</el-table-column>
							<el-table-column label="可划转余额" align="center">
								<template slot-scope="scope">
									<span>{{scope.row.money}}</span>
								</template>
							</el-table-column>
							<el-table-column :label="$t('main.orderMenu.orderAmount')" align="center">
								<template slot-scope="scope">
									<el-input v-model="scope.row.gold" :placeholder="$t('main.orderMenu.pleaseEnterTheAmount')"></el-input>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
					<el-form-item label="谷歌验证码:">
						<el-input v-model="code" placeholder="请输入谷歌验证码"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">确定</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				traList: [],
				business_no: '',
				loading:false,
				form: {
					business_no: '',
				},
				code:'',
				paywayTypeList:[],
				userInfo: {},
			}
		},
		created() {
			this.getInfo()
			this.traderList()
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// console.log('sssssssssss', (value * 1).toFixed(2) + '')
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			//查询基本信息
			getInfo() {
				// this.dialogloading = true
				this.$api.mentionInfo({}).then(res => {
					// this.dialogloading = false;
					if (res.status == 1) {
						this.userInfo = res.data
					}
				}).catch(error => {
					// this.dialogloading = false
				})
			},
			onSubmit() {
				if(isEmpty(this.form.business_no,'请选择商户')){
					return
				}
				let data1 = []
				let totalGold = 0;
				let errorArr=[];
				this.paywayTypeList.forEach(item=>{
					if(Number(item.gold)>0){
						data1.push({"payway_type_id":item.payway_type_id,"money":item.gold})
						totalGold = totalGold+Number(item.gold)
						if(Number(item.gold)>Number(item.money)){
							errorArr.push(`${item.payway_type_name}可划转余额不足`)
						}
					}
				})
				if(totalGold > this.userInfo.money){
					this.$message({type:'error',message:"余额不足"})
					return
				}
				if(data1.length<=0){
					this.$message({type:'error',message:"请输入划转通道金额"})
					return
				}	
				if(errorArr.length>0){
					this.$message({type:'error',message:errorArr[0]});
					return;
				}
				if(isEmpty(this.code,'请输入谷歌验证码')){
					return
				}
				let data={
					business_no:this.form.business_no,
					data:JSON.stringify(data1),
					code:this.code
				}				
				this.loading=true
				this.$api.moneyTransfer(data).then(res=>{
					this.loading=false					
					if(res.status == 1){
						this.form = {
							 business_no: '',
						}
						this.code = ''
						this.business_no=''
						this.paywayTypeList.forEach(item=>{
							item.gold=''
						})
						this.$router.push({path:'/usdtOrder'})
						this.$message({type:'success',message:'划转成功'})
						 this.form = {
							 business_no: '',
						}
						this.code = ''
						this.business_no=''
						this.paywayTypeList.forEach(item=>{
							item.gold=''
						})
						// this.$alert('补增订单编号为:' + res.data, '提示', {
						// 	confirmButtonText: '确定',
						// });
					}else{
						this.$message({type:'error',message:res.msg})
					}
				}).catch(error=>{
					this.loading=false
				})
			},
			traderList() {
				let data = {
					size: 10000,
					page: 1
				}
				this.$api.traderList(data).then(res => {
					if (res.status == 1) {
						this.traList = res.data.data
					}
				})
			},
			
			traderRepayPaywayTypeList() {
				let data = {
					business_no:this.business_no
				}
				this.$api.traderRepayPaywayTypeList(data).then(res => {
					if (res.status == 1) {
						 res.data.forEach(item=>{
							item.gold=''
						})
						this.paywayTypeList = res.data
					}
				})
			},
			chooseTra(val) {
				this.business_no = val	
			},
		
			
		},
		watch: {
			'business_no': function(val, oldval) {
				let hasBusi = false;
				for (let i = 0; i < this.traList.length; i++) {
					if (val == this.traList[i].business_no) {
						// this.money = this.traList[i].money
						this.form.business_no = val;
						hasBusi = true;
						this.traderRepayPaywayTypeList()
						break;
					}
				}
				if(!hasBusi){
					this.form.business_no=''
				}
			}, //键路径必须加上引号
		},
	}
</script>

<style lang="scss" scoped="scoped">
	@import '@/style/variables.scss';

	.content {
		.pageTitle {
			background-color: $navSubColor;
			padding: 10px;
		}

		.tips {
			width: 460px;
		}

		.form {
			width: 550px;
		}
	}
</style>
